'use client';

import dynamic from 'next/dynamic';

const Page = dynamic(() => import('@/components/views/Login'), { ssr: false });

/*
 * Client-only entry point
 */
export function ClientOnlyPage() {
  return <Page />;
}
